import * as React from 'react';
import {withTheme} from 'react-native-paper';
import {Surface, TextInput, Button, Text} from 'react-native-paper';
import {StyleSheet, Image, View} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/Root";
import { useRoute } from '@react-navigation/native';

import i18n from '../translations/Translations';

export interface LoginProps {
    theme: ReactNativePaper.Theme
}

const Login = observer((props: LoginProps) => {
    // global state
    const state = useMst();
    const navigation = useNavigation();
    const route = useRoute();

    // local state
    const [client, setClient] = React.useState(state.customer.id.toString());
    const [secretKey, setSecretKey] = React.useState("");
    const [isClientValid, setClientValid] = React.useState(true);
    const [isSecretKeyValid, setSecretKeyValid] = React.useState(true);

    // fields
    const logo = require('../../assets/logo/' + props.theme.logo);

    // functions
    function validate() {
        return (client.length > 0 && secretKey.length > 0)
    }

    function login() {
        if (validate()) {
            state.customer.setId(client);
            state.customer.setFirstName(client.charAt(0).toUpperCase() + client.slice(1));
            navigation.navigate('Learning');
        }
    }

    function clear() {
        console.log("clearing")
        setClient("");
        setSecretKey("");
        setClientValid(true);
        setSecretKeyValid(true);
        state.customer.setId("");
    }

    // Check is screen displayed after logging out
    // if (route && route.params && route.params.loggedOut){
    //     clear();
    //     route.params.loggedOut = false;
    // }

    return (
        <Surface style={styles.surface}>
            {state.customer.id.length > 0 &&
            <Text>Previously logged in as {state.customer.id}</Text>
            }
            <Image style={styles.logo} source={logo}/>

            <TextInput style={styles.input}
                       mode="flat"
                       label={i18n.t("loginPage.client")}
                       error={!isClientValid}
                       value={client}
                       onChangeText={text => {
                           setClient(text);
                           setClientValid(text.length > 0);
                       }}
            />
            <TextInput style={styles.input}
                       mode="flat"
                       error={!isSecretKeyValid}
                       secureTextEntry={true}
                       label={i18n.t("loginPage.secretKey")}
                       value={secretKey}
                       onChangeText={text => {
                           setSecretKey(text);
                           setSecretKeyValid(text.length > 0);
                       }}
            />
            <View style={styles.buttonsContainer}>
                <Button style={styles.button} icon="login" mode="contained" onPress={() => login()}>
                    {i18n.t("loginPage.loginButton")}
                </Button>
                <Button style={styles.button} icon="clear" mode="contained" onPress={() => clear()}>
                    {i18n.t("loginPage.clearButton")}
                </Button>
            </View>
        </Surface>
    );
});

const styles = StyleSheet.create({
    surface: {
        padding: 20,
        width: 450,
        elevation: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        width: "100%",
        marginBottom: 20,
    },
    logo: {
        width: 450,
        height: 200,
        marginBottom: 40,
        resizeMode: "contain",
        alignSelf: "center"
    },
    button: {
        marginTop: 40,
        marginHorizontal: 10
    },
    buttonsContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

export default withTheme(Login);