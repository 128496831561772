import * as React from 'react';
import {types} from "mobx-state-tree";
import {Component} from "react";
import {Client} from "../models/Client";

export class NetworkService {
    static baseURL = "https://daniel_cnc_test.cncdev.aji.digital/";

    static async getRequest(url: String, callback: Function, params?: Object) {
        let sendURL = this.baseURL + url;
        fetch(this.baseURL + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => response.json())
            .then((responseData) => {
                callback(responseData);
            });
    }


}