import * as React from 'react';

export class NetworkService {
    static baseURL = "https://daniel_cnc_test.cncdev.aji.digital";
    static boBaseURL = "https://dev.mywall.digital/";
    // static baseURL = "http://localhost:7000";
    // static boBaseURL = "http://localhost:8000";

    static async getRequest(url: String, params?: object, isSymfonyParams?: boolean) {
        let sendURL = this.baseURL + "/" + url;

        if (typeof params !== "undefined"){
            if (isSymfonyParams){
                for (let [key, value] of Object.entries(params)) {
                    sendURL += "/" + key + "/" + value;
                }
            }else {
                // Standard get parameters handling
                let query = "";
                for (let [key, value] of Object.entries(params)) {
                    if (query.length > 0) {
                        query += "&";
                    }
                    query += key + "=" + value;
                }
                if (query.length > 0){
                    sendURL += "?" + query;
                }
            }
        }

        const response = await fetch(sendURL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        if (response.status != 200){
            return {status: response.status};
        }
        console.log(response);
        return response.json();
    }

    static async postRequest(url: String, params?: Object) {
        const sendURL = this.baseURL + "/" + url;
        if (typeof params === "undefined"){
            params = {};
        }
        const response = await fetch(sendURL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        });
        if (response.status != 200){
            return {status: response.status};
        }

        try {

            return response.json();
        }catch (e){
            return {status: response.status};
        }

    }

    static async putRequest(url: String, params?: Object) {
        const sendURL = this.baseURL + "/" + url;
        if (typeof params === "undefined"){
            params = {};
        }
        const response = await fetch(sendURL, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        });

        return response.json();
    }

    static async deleteRequest(url: String, params?: Object) {
        const sendURL = this.baseURL + "/" + url;
        if (typeof params === "undefined"){
            params = {};
        }
        const response = await fetch(sendURL, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        });
    }


}