import React, {createContext} from 'react';
import {Provider, RootStore, useMst} from "./models/RootModel";
import {Provider as PaperProvider} from 'react-native-paper';
import {ClientTheme, MarketPlaceTheme} from './themes/AjiTheme';
import HomePage from "./pages/HomePage";
import ClientPage from "./pages/ClientPage";
import {useFonts} from 'expo-font';
import '@expo/match-media';
import LogRocket from 'logrocket';
import {createStackNavigator} from "@react-navigation/stack";
import {NavigationContainer, useNavigation} from "@react-navigation/native";
import CartPage from "./pages/CartPage";
import ProductPage from "./pages/ProductPage";
import LoginPage from "./pages/LoginPage";
import AccountPage from "./pages/AccountPage";
import CategoryPage from "./pages/CategoryPage";
import SearchResultPage from "./pages/SearchResultPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CreateAccountPage from "./pages/CreateAccountPage";

LogRocket.init('dsfc92/marketplace');

const Stack = createStackNavigator();


export default function App() {
    let [fontsLoaded] = useFonts({
        'Poppins-Medium': require('../assets/fonts/Poppins/Poppins-Medium.ttf'),
        'Poppins-Regular': require('../assets/fonts/Poppins/Poppins-Regular.ttf'),
        'Poppins-Bold': require('../assets/fonts/Poppins/Poppins-Bold.ttf'),
        'Poppins-ExtraBold': require('../assets/fonts/Poppins/Poppins-ExtraBold.ttf'),
        'Poppins-Black': require('../assets/fonts/Poppins/Poppins-Black.ttf'),
        'Poppins-Light': require('../assets/fonts/Poppins/Poppins-Light.ttf'),
    });


    const config = {
        screens: {
            Home: "home/:category?",
            Cart: "cart",
            ClientHome: "restaurant",
            ProductPage: {
                path: "product/:id",
                parse: {
                    id: (id: string) => id
                }
            },
            LoginPage: "login",
            AccountPage: "account/:tab?",
            CategoryPage: "category/:id",
            SearchResultPage: "search",
            ForgotPassword: "forgot_password",
            CreateAccount: "create_account"
        },
    };

    const linking = {
        prefixes: [window.location.origin + "/"],
        config,
    };


    if (!fontsLoaded) {
        // Avoid displaying unloaded fonts
        return null;
    } else {
        return (
            <Provider value={new RootStore}>
                <PaperProvider theme={MarketPlaceTheme}>
                    <NavigationContainer linking={linking}>
                        <Stack.Navigator initialRouteName={"Home"} screenOptions={{headerShown: false}}>
                            <Stack.Screen name={"Home"} component={HomePage}/>
                            <Stack.Screen name={"ClientHome"}>
                                {props => <ClientPage theme={ClientTheme}/>}
                            </Stack.Screen>
                            <Stack.Screen name={"Cart"} component={CartPage}/>
                            <Stack.Screen name={"ProductPage"} component={ProductPage}/>
                            <Stack.Screen name={"LoginPage"} component={LoginPage}/>
                            <Stack.Screen name={"AccountPage"} component={AccountPage}/>
                            <Stack.Screen name={"CategoryPage"} component={CategoryPage}/>
                            <Stack.Screen name={"SearchResultPage"} component={SearchResultPage}/>
                            <Stack.Screen name={"ForgotPassword"} component={ForgotPasswordPage}/>
                            <Stack.Screen name={"CreateAccount"} component={CreateAccountPage}/>
                        </Stack.Navigator>
                    </NavigationContainer>
                </PaperProvider>
            </Provider>
        );
    }
}
