import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {observer} from "mobx-react-lite";
import i18n from "../translations/Translations";
import {IAddressModel} from "../models/AddressModel";

export interface AddressProps {
    theme: ReactNativePaper.Theme
    address: IAddressModel
    callback?: (address: IAddressModel) => {}
    readonly?: boolean
}

const Address = observer((props: AddressProps) => {

        const styles = StyleSheet.create({
            container: {},
            name: {
                letterSpacing: 2.5,
                fontFamily: props.theme.fontList.primaryFontMedium,
            },
            address: {
                letterSpacing: 1,
                lineHeight: 24,
                fontFamily: props.theme.fontList.primaryFontLight,
                marginTop: 6
            },
            edit_btn:{
                color: props.theme.colors.button_color,
                fontFamily: props.theme.fontList.primaryFontDefault,
                letterSpacing: 1.3
            }
        });


        function editCallback() {
            if (typeof props.callback === "function") {
                props.callback(props.address);
            }
        }

        let footer = <View style={styles.container}>
            <Text style={styles.name}>{props.address.name}</Text>
            <Text style={styles.address}>{props.address.getFormatted()}</Text>
            {!props.readonly &&
            <TouchableOpacity style={{marginTop: 10}} activeOpacity={props.theme.defaultTouchableOpacity} onPress={editCallback}>
                <Text style={styles.edit_btn}>{i18n.t("common.edit")}</Text>
            </TouchableOpacity>
            }
        </View>
        return footer;
    }
);

export default withTheme(Address);