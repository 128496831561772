export default {
    loginPage: {
        client: "Client",
        username: "Username",
        password: "Password",
        secretKey: "Secret Key",
        loginButton: "Login",
        logOutButton: "Log out",
        clearButton: "Clear",
        rememberMe: "Remember me",
    }
}