import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {AddressModel, IAddressModel} from "../models/AddressModel";
import Address from "./Address";

export interface MyAddressesProps {
    theme: ReactNativePaper.Theme
}

const MyAddresses = observer((props: MyAddressesProps) => {
    const state = useMst();
    const styles = StyleSheet.create({
        content_wrapper: {},
        content_title: {
            fontFamily: props.theme.fontList.primaryFontBold,
            fontSize: 18,
            letterSpacing: 5.5,
            marginTop: 12,
        },
        addresses_wrapper:{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 40
        }
    });

    // TODO Take adressess from customer
    const addressesArray = [AddressModel.create({
        name: "HOME",
        firstName: "John",
        lastName: "Doe",
        street: "Little Street 212",
        postal: "000-000",
        city: "MyCity"
    }), AddressModel.create({
        name: "WORK",
        firstName: "Daniel",
        lastName: "Andrysek",
        street: "Bednorza tralalala",
        postal: "40-384",
        city: "Katowice"
    })];

    const addressesComponents = addressesArray.map((address: IAddressModel) => (
        <Address key={address.name} address={address}/>
    ))

    return (
        <View style={styles.content_wrapper}>
            <Text style={styles.content_title}>{i18n.t("account.my_addresses.title")}</Text>
            <View style={styles.addresses_wrapper}>
                {addressesComponents}
            </View>
        </View>
    );
});

export default withTheme(MyAddresses);