import {NetworkService} from "../services/NetworkService";
import {Instance, types} from "mobx-state-tree";
import {makeAutoObservable} from "mobx";
import {runInAction} from "mobx";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {IProductModel} from "./ProductModel";
import {useMst} from "./RootModel";

export class ProductsCategoriesStore {
    categories:Array<IProductCategoryModel>;

    constructor() {
        this.categories = [ProductCategoryModel.create()];

        makeAutoObservable(this);
        this.loadCategories();
    }

    loadCategories() {
        NetworkService.getRequest("api/product_categories").then((categoriesJson: Array<IProductCategoryModel>) => {
            runInAction(() => {
                this.categories = [];

                // Sort categories by the index
                categoriesJson.sort((a:IProductCategoryModel, b:IProductCategoryModel) => {
                    if (a.index < b.index){
                        return -1;
                    }
                    if (a.index > b.index){
                        return 1;
                    }
                    return 0;
                });
                for (let i = 0; i < categoriesJson.length; i++) {
                    let category = categoriesJson[i];
                    // In case of empty description data type is Array instead of Object so we need to override it
                    if (Object.keys(category.description).length == 0) {
                        category.description = TranslatedTextModel.create({});
                    }
                    this.categories.push(ProductCategoryModel.create(category))

                }
            })
        }).catch(error => {
            console.log("Could not get product categories");
            console.log(error);
        })
    }
}

/**
 * Image is optional because when assigned to product we haven't got enough data
 */
export const ProductCategoryModel = types.model({
    id: "",
    name: types.optional(TranslatedTextModel, {}),
    description: types.optional(TranslatedTextModel, {}),
    index: 0,
    image: types.optional(types.string, "")
}).actions((self) => ({

})).views((self => ({
    getProducts(products:Array<IProductModel>): Array<IProductModel> {
        return products.filter((product:IProductModel) => product.category.id == self.id);
    },
    getImage(): string{
        return NetworkService.boBaseURL + self.image;
    }
})));

export interface IProductCategoryModel extends Instance<typeof ProductCategoryModel> {
}