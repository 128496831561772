export default {
    loginPage: {
        client: "Klient",
        username: "Użytkownik",
        password: "Hasło",
        secretKey: "Tajny Klucz",
        loginButton: "Zaloguj",
        logOutButton: "Wyloguj",
        clearButton: "Wyczyść",
        rememberMe: "Zapamiętaj mnie",
    }
}