import { types, Instance, onSnapshot } from "mobx-state-tree";

const CustomerModel = types
    .model({
        id: types.string,
        firstName: types.string,
        lastName: types.string,
        phoneNumber: types.string,
        email: types.string
    })
    .actions(self => ({
        setId(v: string) {
            self.id = v;
        },
        setFirstName(v: string) {
            self.firstName = v;
        },
        setLastName(v: string) {
            self.lastName = v;
        },
        setPhoneNumber(v: string) {
            self.phoneNumber = v;
        },
        setEmail(v: string) {
            self.email = v;
        }
    }))

let initialState = CustomerModel.create({
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: ""
});

// check if previously saved store is available
const data = localStorage.getItem('customerState');
if (data) {
    const json = JSON.parse(data);
    if (CustomerModel.is(json)) {
        initialState = CustomerModel.create(json);
    }
}

export const customerStore = initialState;

// store state in local storage on each change
onSnapshot(customerStore, snapshot => {
    //console.log("Snapshot: ", snapshot);
    localStorage.setItem('customerState', JSON.stringify(snapshot));
});
