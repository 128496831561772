import React from 'react';
import {Provider, RootStore} from "./models/Root";
import {StyleSheet, View} from 'react-native';
import {Provider as PaperProvider} from 'react-native-paper';
import {CombinedDefaultTheme} from './themes/AjiTheme';
import LoginPage from './pages/LoginPage';
import LearningPage from "./pages/LearningPage";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import HomePage from "./pages/HomePage";

const Stack = createStackNavigator();

export default function App() {
    return (
        <Provider value={new RootStore}>
            <PaperProvider theme={CombinedDefaultTheme}>
                <NavigationContainer>
                    <Stack.Navigator headerMode="none" initialRouteName="Home">
                        <Stack.Screen name="Home" component={LoginPage}/>
                    </Stack.Navigator>
                </NavigationContainer>
            </PaperProvider>
        </Provider>
    );
}
