import {Instance, types} from "mobx-state-tree";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {PriceModel} from "./PriceModel";
import {NetworkService} from "../services/NetworkService";
import {IProductModel, ProductModel} from "./ProductModel";
import {useMst} from "./RootModel";

const OrderItemAddonRefModel = types.model({
    id: "",
    name: types.optional(TranslatedTextModel, {}),
    description: types.optional(TranslatedTextModel, {}),
    image: ""
}).views(self => ({
    getImage(): string {
        return NetworkService.boBaseURL + self.image;
    }
}));

export const OrderItemAddonModel = types.model({
    id: "",
    product: types.optional(OrderItemAddonRefModel, {}),
    priceCur: "",
    price: 0
}).actions(self => ({})).views(self => ({
    getPrice(alwaysShowDecimals: boolean): string {
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
}));

export interface IOrderItemAddonModel extends Instance<typeof OrderItemAddonModel> {
}