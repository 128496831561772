import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Header from '../components/ClientHeader';
import Home from '../components/Home';
import OptionsPopup from "../components/OptionsPopup";
import {Modal, Provider as PaperProvider, withTheme} from "react-native-paper";
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {OrderHandler} from "../models/OrderModel";
import AddonsPopup from "../components/AddonsPopup";
import OrderPopup from "../components/OrderPopup";
import {useMediaQuery} from "react-responsive";
import MobileHeader from "../components/MobileHeader";
import i18n from "../translations/Translations";
import ErrorPopup from "../components/ErrorPopup";
import WebsocketService from "../services/WebsocketService";
import OrderStatusPopup from "../components/OrderStatusPopup";
import {ClientTheme} from "../themes/AjiTheme";

export interface HomePageProps {
    theme: ReactNativePaper.Theme
}

const ClientPage = observer(() => {
    const state = useMst();

    const orderHandler = new OrderHandler(state);
    const desktopVersion = useMediaQuery({minWidth: 1074});

    if (state.customer.orderID.length > 0 && state.customer.orderID != state.currentOrder.id) {
        orderHandler.resumeOrder(state.customer.orderID);
    }

    let showStatusPopup = false;
    if (["preparing", "rejected", "ready_for_pickup", "waiting_approval"].indexOf(state.currentOrder.status) > -1){
        const wsInstance = WebsocketService.getInstance(state);
        showStatusPopup = true;
    }

    return (
        <PaperProvider theme={ClientTheme}>
        <View style={styles.container}>
            <ScrollView style={{height: "100vh"}}>
                <Header/>
                <Home/>
            </ScrollView>
            {!desktopVersion &&
            <MobileHeader/>
            }

            <Modal visible={state.errorPopup.isError} contentContainerStyle={styles.modal}>
                <ErrorPopup/>
            </Modal>

            <Modal visible={showStatusPopup} contentContainerStyle={styles.modal}>
                <OrderStatusPopup modalVisible={showStatusPopup}/>
            </Modal>

            {!state.errorPopup.isError &&
            <Modal visible={state.optionsPopup.isPopupVisible}  contentContainerStyle={styles.modal}>
                <OptionsPopup/>
            </Modal>
            }

            {!state.errorPopup.isError &&
            <Modal visible={state.addonsPopup.isPopupVisible} contentContainerStyle={styles.modal}>
                <AddonsPopup/>
            </Modal>
            }
            {!state.errorPopup.isError &&
            <Modal visible={state.orderPopup.isPopupVisible} contentContainerStyle={styles.modal}>
                <OrderPopup/>
            </Modal>
            }
        </View>
        </PaperProvider>
    );
})

// export default function ClientPage() {
//
// }

const styles = StyleSheet.create({
    container: {
        backgroundColor: "white"
    },
    modal: {
        elevation: 0
    }
});

export default withTheme(ClientPage);