import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, TouchableOpacity, View, Text} from 'react-native';
import {observer} from "mobx-react-lite";
//TODO ADD PROPER WAY TO DIFFERENT BETWEEN TOW OF DISPLAY ITEMS
// import DisplayItem from "./GenericProductDisplayItem";
import DisplayItem from "./GenericRectangleProductDisplayItem";
import {IProductModel} from "../models/ProductModel";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import i18n from "../translations/Translations";
import {useNavigation} from "@react-navigation/native";
import {useMst} from "../models/RootModel";


export interface ProductsListProps {
    theme: ReactNativePaper.Theme,
    products: Array<IProductModel>,
    marginHorizontal?: number,
    maxProducts?: number,
    category?: IProductCategoryModel
}

const ProductsList = observer((props: ProductsListProps) => {

    let productsArray = [];
    let moreButton = null;
    const navigation = useNavigation();
    const state = useMst();

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
        },
        more_button: {
            backgroundColor: props.theme.colors.button_color,
            alignContent: "center",
            justifyContent: "center",
            alignSelf: "center",
            padding: 8,
            marginTop: 28
        },
        more_button_text: {
            alignSelf: "center",
            color: "white",
            fontFamily: props.theme.fontList.primaryFontBold,
            letterSpacing: 2,
            paddingHorizontal: 8
        }
    });

    function generateMoreButton() {
        return <View style={{width: "100%"}}>
            <TouchableOpacity style={styles.more_button} activeOpacity={props.theme.defaultTouchableOpacity}
                              onPress={() => {
                                  if (props.category && props.category.id) {
                                      state.searchString.setValue("");
                                      navigation.navigate("CategoryPage", {id: props.category.id})
                                  }
                              }}>
                <Text
                    style={styles.more_button_text}>{i18n.t("product_list.load_more_button")}
                </Text>
            </TouchableOpacity></View>
    }

    for (let i = 0; i < props.products.length; i++) {
        if (props.maxProducts && props.category && productsArray.length >= (props.maxProducts)) {
            moreButton = generateMoreButton();
            //insert more products button
            break;
        }
        const product = props.products[i];
        productsArray.push(<DisplayItem key={product.id} product={product}
                                        marginHorizontal={props.marginHorizontal}/>);
    }

    let dummyProductsArray = [];
    if (props.products.length) {
        for (let i = 0; i < 8; i++) {
            dummyProductsArray.push(<DisplayItem key={i} product={props.products[0]} dummy={true}
                                                 marginHorizontal={props.marginHorizontal}/>);
        }
    }

    return (
        <View style={styles.container}>
            {productsArray}
            {dummyProductsArray}
            {moreButton}
        </View>
    );
});

export default withTheme(ProductsList);