import {useContext, createContext} from "react";
import {types, Instance, onSnapshot} from "mobx-state-tree";

import {ClientStore} from "./Client";
import {customerStore} from "./Customer";
import {NetworkService} from '../services/NetworkService'




export class RootStore {
    clientStore;
    customer;

    constructor() {
        this.clientStore = new ClientStore();
        this.customer = customerStore;
    }
}

// create context and provider to allow easy access to the central store from any component
const RootStoreContext = createContext<null | RootStore>(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}