import {Instance, types} from "mobx-state-tree";


export const AddressModel = types.model({
    name: types.string,
    firstName: types.string,
    lastName: types.string,
    street: types.string,
    postal: types.string,
    city: types.string
}).actions(self => ({

})).views(self => ({
    getFormatted() : string {
        let result = self.firstName + " " + self.lastName + "\n";
        result += self.street + "\n";
        result += self.postal + " " + self.city;
        return result;
    }
}));

export interface IAddressModel extends Instance<typeof AddressModel>{}