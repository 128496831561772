import {cast, Instance, types} from "mobx-state-tree";
import {TranslatedTextModel} from "./TranslatedTextModel";
import {PriceModel} from "./PriceModel";
import {NetworkService} from "../services/NetworkService";
import {IProductModel, ProductModel} from "./ProductModel";
import {useMst} from "./RootModel";

const AddonRefModel = types.model({
    id: "",
    name: types.optional(TranslatedTextModel, {}),
    description: types.optional(TranslatedTextModel, {}),
    image: ""
}).views(self => ({
    getImage(): string {
        return NetworkService.boBaseURL + self.image;
    }
}));

export const AddonModel = types.model({
    id: "",
    addon: types.optional(AddonRefModel, {}),
    selectedOptions: types.array(types.string),
    priceCur: "",
    price: 0,
    selected: false
}).actions(self => ({
    setSelected(v: boolean) {
        self.selected = v;
    },
    toggleSelected() {
        self.selected = !self.selected;
    },
    setOptions(options: Array<string>) {
        console.log(options);
        self.selectedOptions = cast(options);
    }
})).views(self => ({
    //TODO CHECK USAGES!!!
    getPrice(alwaysShowDecimals: boolean): string {
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
    getProduct(): IProductModel {
        const state = useMst();
        for (let i = 0; i < state.productsStore.products.length; i++) {
            const product = state.productsStore.products[i];
            if (product.id == self.addon.id) {
                return product;
            }
        }

        return ProductModel.create({});
    }
}));

export interface IAddonModel extends Instance<typeof AddonModel> {
}