import {Text, withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import {useNavigation} from "@react-navigation/native";
export interface CategoriesDropDownProps {
    theme: ReactNativePaper.Theme
}

const CategoriesDropDown = observer((props: CategoriesDropDownProps) => {
    const state = useMst();
    const [selectedCategory, selectCategory] = React.useState("");
    const [isActive, setIsActive] = React.useState(false);
    const navigation = useNavigation();

    const styles = StyleSheet.create({
        container: {
            backgroundColor: "white",
            position: "relative",
            zIndex: 9,
            minWidth: 250,
            borderColor: "black",
            paddingHorizontal: 12
        },
        dropdown_button: {
            alignSelf: "flex-start",
            alignContent: "center",
            justifyContent: "center",
            height: 50,
        },
        dropdown: {
            position: "absolute",
            width: "100%",
            top: 50,
            left: 0,
            zIndex: 9,
            backgroundColor: "white"
        },
        navigation_left: {
            flexDirection: "row",
            paddingRight: 16//this is to extend the touchable zone so it feels more natural
        },
        navigation_text: {
            fontFamily: props.theme.fontList.primaryFontMedium,
            textTransform: "uppercase",
            fontSize: 18,
            letterSpacing: 2,
            alignSelf: "center",
            alignContent: "center",
            justifyContent: "center",
        },
        category_text: {
            fontFamily: props.theme.fontList.primaryFontMedium,
            textTransform: "uppercase",
            fontSize: 18,
            letterSpacing: 2,
            paddingVertical: 12
        },
        selected_category_text: {
            color: props.theme.colors.button_color
        }
    });

    //this is local variable and we don't want it to be a state
    let optionPressed = false;
    let dropDownButton = <TouchableOpacity style={styles.dropdown_button} activeOpacity={props.theme.defaultTouchableOpacity} onPress={() => {
        setIsActive(!isActive);
    }} onBlur={(e) => {
        setTimeout(() => {
            if (optionPressed) {
                optionPressed = false;
                return;
            }
            setIsActive(false);
        }, 50)
    }}>
        <View style={styles.navigation_left}>
            <Text style={[styles.navigation_text, {paddingRight: 8}]}>Categories</Text>
            <FontAwesome style={{marginBottom: 4}} name={isActive ? "angle-up" : "angle-down"}
                         size={28}/>
        </View>
    </TouchableOpacity>

    if (!isActive) {
        return <View style={styles.container}>
            {dropDownButton}
        </View>
    }

    return <View style={styles.container}>
        {dropDownButton}
        <View style={styles.dropdown}>
            {state.categoriesStore.categories.map((category: IProductCategoryModel) => (
                <TouchableOpacity key={category.id} onPress={() => {
                    setIsActive(false);
                    state.searchString.setValue("");
                    navigation.navigate("CategoryPage", {id: category.id});
                }} onPressIn={() => {
                    optionPressed = true;
                }}><Text
                    style={[styles.category_text, ((category.id == selectedCategory) ? styles.selected_category_text : {})]}>{category.name.getTranslated()}</Text></TouchableOpacity>
            ))
            }</View>
    </View>;
});

export default withTheme(CategoriesDropDown);