import { makeAutoObservable, autorun, runInAction } from "mobx";
import {NetworkService} from "../services/NetworkService";

export class ClientStore {
    client: Client;

    constructor() {
        makeAutoObservable(this);
        this.client = new Client();
        this.loadClient();
    }

    loadClient(){
        let customer = new Client();
        NetworkService.getRequest('/api/clients/current', (customerJson: Object) => {
            customer.loadFromJson(customerJson);
        });

        this.client = customer;
    }
}


export class Client {
    name = '';
    address = '';
    phoneNumber = '';

    constructor() {
        makeAutoObservable(this);
    }

    loadFromJson(json: Object){
        console.log(json);
        this.name = json.name;
        this.address = json.address;
        this.phoneNumber = json.phoneNumber;
    }
}