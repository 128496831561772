import {cast, Instance, types} from "mobx-state-tree";
import {NetworkService} from "../services/NetworkService";
import {RootStore} from "./RootModel";
import {IOrderItemModel, OrderItemModel} from "./OrderItemModel";
import {PriceModel} from "./PriceModel";
import {string} from "mobx-state-tree/dist/types/primitives";
import {IAddonModel} from "./AddonModel";
import moment from "moment";
import {ProductModel} from "./ProductModel";
import {runInAction} from "mobx";
import WebsocketService from "../services/WebsocketService";

export class OrderHandler {
    state;

    constructor(state: RootStore) {
        this.state = state;
    }

    initialize(callback?: () => void) {
        NetworkService.postRequest("api/orders/create").then(data => {
            runInAction(() => {
                this.state.currentOrder.setID(data.id);
                this.state.currentOrder.setItems([]);
                this.state.currentOrder.setPrice(data.price);
                this.state.currentOrder.setPriceCurrency(data.priceCur);
                this.state.currentOrder.setStatus(data.status);
                this.state.customer.setOrderID(data.id);
                this.state.customer.setId("");

                if (typeof callback === "function") {
                    callback();
                }
            })
        }).catch(error => {
            console.log("COULD NOT CREATE ORDER");
            console.log(error)
            this.state.currentOrder.setID("");
            this.state.currentOrder.setPrice(0);
            this.state.currentOrder.setPriceCurrency("");
            this.state.currentOrder.setStatus("new");
            this.state.customer.setOrderID("");
        })
    }

    resumeOrder(orderID: string, callback?: () => void) {
        this.state.currentOrder.setID(orderID);
        this.refresh(true, callback);
    }

    refresh(resume: boolean, callback?: () => void) {
        NetworkService.getRequest("api/orders/" + this.state.currentOrder.id).then(data => {
            if (resume && ['new', 'creating', 'preparing', 'rejected', 'ready_for_pickup', 'waiting_approval', 'waiting_payment'].indexOf(data.status) === -1) {
                const websocketInstance = WebsocketService.getInstance(this.state);
                websocketInstance.close();
                this.removeOrder();
                this.initialize(callback);
                return;
            }
            console.log(data)
            this.state.currentOrder.setPrice(data.price);
            this.state.currentOrder.setPriceCurrency(data.priceCur);
            this.state.currentOrder.setStatus(data.status);
            this.state.currentOrder.setItems(data.products);
            this.state.currentOrder.setNumber(data.number);
            if (data.expectedDeliveryDate !== null) {
                this.state.currentOrder.setDeliveryDate(data.expectedDeliveryDate);
            }

            if (typeof callback === "function") {
                callback();
            }
        }).catch(error => {
            console.log("Could not refresh order.");
            console.log(error);
            this.removeOrder();
        })
    }

    removeOrder() {
        this.state.currentOrder.setItems([]);
        this.state.currentOrder.setID("");
        this.state.customer.setOrderID("");
        this.state.customer.setId("");
    }

    addProduct(productID: string, options: Array<string>, callback?: () => void) {
        NetworkService.putRequest("api/orders/" + this.state.currentOrder.id + "/add/" + productID, {options: options}).then(response => {
            if (response.products) {
                let productsKeys = Object.keys(response.products);
                this.state.addonsPopup.setCurrentOrderItemID(response.products[productsKeys[productsKeys.length - 1]].id);
            }
            this.refresh(false, callback);
        }).catch(error => {
            console.log("Could not add product");
            console.log(error);
        })
    }

    removeOrderItem(orderItem: IOrderItemModel, callback?: () => void) {
        NetworkService.deleteRequest("api/order_items/" + orderItem.id).then((() => {
            this.refresh(false, callback);
        })).catch(error => {
            console.log("Could not remove order item");
            console.log(error);
        })
    }

    incrementProductQuantity(orderItem: IOrderItemModel, callback?: () => void) {
        NetworkService.putRequest("api/order_items/" + orderItem.id + "/increment").then(((response) => {
            this.refresh(false, callback);
        })).catch(error => {
            console.log("Could not increment order item");
            console.log(error);
        })
    }

    decrementProductQuantity(orderItem: IOrderItemModel, callback?: () => void) {
        NetworkService.putRequest("api/order_items/" + orderItem.id + "/decrement").then(((response) => {
            this.refresh(false, callback);
        })).catch(error => {
            console.log("Could not decrement order item");
            console.log(error);
        })
    }

    addAddons(addons: Array<IAddonModel>, callback?: () => void) {
        if (addons.length === 0) {
            if (typeof callback === "function") {
                callback();
            }
            return;
        }
        const addon = addons.pop();

        // Checking if not null just to suppress Idea error underline
        if (addon != null) {
            this.addAddon(addon, false, () => {
                if (addons.length > 0) {
                    this.addAddons(addons, callback);
                } else {
                    this.refresh(false, callback);
                }
            });
        }

    }

    addAddon(addon: IAddonModel, withRefresh: boolean, callback?: () => void) {
        console.log('Adding addon')
        NetworkService.putRequest("api/order_items/" + this.state.addonsPopup.currentOrderItemID + "/add/" + addon.id, {options: addon.selectedOptions}).then(((response) => {
            if (withRefresh) {
                this.refresh(false, callback);
                return;
            }
            if (typeof callback === "function") {
                callback();
            }
        })).catch(error => {
            console.log("Could not add addon");
            console.log(error);
        })
    }

    cancel(callback?: () => void) {
        NetworkService.putRequest("api/orders/" + this.state.currentOrder.id + "/cancel").then((response) => {
            this.removeOrder();
        }).catch(error => {
            console.log("Could not add addon");
            console.log(error);
        })
    }

    createCustomer(callback?: () => void) {
        if (this.state.customer.id.length > 0) {
            if (typeof callback === "function") {
                callback();
            }
        } else {
            NetworkService.postRequest("api/customers", {
                phoneNumber: this.state.customer.phoneNumber,
                email: this.state.customer.email,
                firstName: this.state.customer.firstName,
                name: this.state.customer.lastName
            }).then((response) => {
                this.state.customer.setId(response.id);
                if (typeof callback === "function") {
                    callback();
                }
            }).catch(error => {
                console.log("Could not create customer");
                console.log(error);
            })
        }
    }

    setCustomer(callback?: () => void) {
        if (this.state.currentOrder.customerAssigned) {
            if (typeof callback === "function") {
                callback();
            }
        } else {
            NetworkService.putRequest("api/orders/" + this.state.currentOrder.id + "/customer/" + this.state.customer.id).then((response) => {
                this.state.currentOrder.setCustomerAssigned(true);
                if (typeof callback === "function") {
                    callback();
                }
            }).catch(error => {
                console.log("Could not assign customer");
                console.log(error);
            })
        }
    }

    checkout(payment: string, deliveryDate: string, callback?: () => void) {
        NetworkService.postRequest("api/orders/" + this.state.currentOrder.id + "/checkout/" + payment + "/collection/" + deliveryDate).then((response) => {
            this.state.currentOrder.setStatus(response.status);
            if (typeof callback === "function") {
                callback();
            }
        }).catch(error => {
            console.log("Could not assign customer");
            console.log(error);
        })
    }
}

export const OrderModel = types.model({
    id: "",
    price: 0,
    priceCur: "",
    status: "",
    items: types.optional(types.array(OrderItemModel), []),
    deliveryDate: "",
    deliveryTimer: "",
    number: 0,
    customerAssigned: false,
    created: ""
}).actions(self => ({
    setID(v: string) {
        self.id = v;
    },
    setPrice(v: number) {
        self.price = v;
    },
    setPriceCurrency(v: string) {
        self.priceCur = v;
    },
    setStatus(v: string) {
        self.status = v;
    },
    setItems(v: Array<IOrderItemModel>) {
        self.items = cast(v);
    },
    setDeliveryDate(v: string) {
        self.deliveryDate = v;
    },
    setCustomerAssigned(v: boolean) {
        self.customerAssigned = v;
    },
    setNumber(v: number) {
        self.number = v;
    }
})).views(self => ({
    getPrice(alwaysShowDecimals: boolean): string {
        let price = PriceModel.create({price: self.price, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
    getFormatedPrice(amount:number,alwaysShowDecimals: boolean): string{
        let price = PriceModel.create({price: amount, currency: self.priceCur});
        return price.getFormatedPrice(alwaysShowDecimals);
    },
    getDeliveryTime(): number {
        if (self.deliveryDate) {
            let now = moment(new Date()).utc();
            let delivery = moment(self.deliveryDate).utc();
            let duration = moment.duration(delivery.diff(now));
            return Math.floor(duration.asMinutes() + 1);
        }
        return 0;
    },
    getFormattedCreateDate(format: string): string{
        return moment(self.created).format(format);
    }
}));
export interface IOrderModel extends Instance<typeof OrderModel>{}

let initialState = OrderModel.create();

export const orderStore = initialState;