import * as Localization from "expo-localization";
import i18n from "i18n-js";
import engb from "../translations/engb";
import frfr from "../translations/frfr";
import plpl from "../translations/plpl";


i18n.translations = {
  en: engb,
  "en-US": engb,
  fr: frfr,
  pl: plpl,
};

i18n.locale = Localization.locale;
i18n.fallbacks = true;

export default i18n;