import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {Text} from 'react-native-paper';
import {ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import i18n from "../translations/Translations";
import {IOrderItemModel, OrderItemModel} from "../models/OrderItemModel";
import {DataTable} from 'react-native-paper';
import {NetworkService} from "../services/NetworkService";
import {IOrderModel, OrderModel} from "../models/OrderModel";
import {useNavigation} from "@react-navigation/native";

export interface MyOrdersProps {
    theme: ReactNativePaper.Theme,
}

const itemsPerPage = 10;


const MyOrders = observer((props: MyOrdersProps) => {
        const state = useMst();
        const [page, setPage] = React.useState(0);
        const [data, setData] = React.useState<IOrderModel[]>([]);
        const from = page * itemsPerPage;
        const to = (page + 1) * itemsPerPage;

        React.useEffect(() => {
            NetworkService.getRequest("api/customers/" + state.customer.id + "/orders").then(response => {
                if (Array.isArray(response)) {
                    let updatedData = [];
                    for (let i = 0; i < response.length; i++) {
                        updatedData.push(OrderModel.create(response[i]))
                    }
                    setData(updatedData);
                }
            });
        }, []);


        const styles = StyleSheet.create({
            content_title: {
                fontFamily: props.theme.fontList.primaryFontBold,
                fontSize: 18,
                letterSpacing: 5.5,
                marginTop: 12,
            },
            container: {
                flex: 1,
                padding: 16,
                paddingTop: 30,
                backgroundColor: '#fff'
            },
            header: {
                height: 50,
                backgroundColor: '#537791'
            },
            text: {
                textAlign: 'center',
                fontWeight: '100'
            },
            dataWrapper: {
                marginTop: -1
            },
            row: {
                height: 40,
                backgroundColor: '#E7E6E1'
            },
            table: {
                marginTop: 20,
                borderWidth: 1
            },
            table_row: {
                borderBottomWidth: 0,
            },
            table_row_even: {
                borderBottomWidth: 0,
                backgroundColor: "#eeeeee"
            },
            cell_with_right_border: {
                borderRightWidth: 1
            },
            cell: {
                paddingHorizontal: 10
            }
        });


        return (
            <View style={{width: 700}}>
                <Text style={styles.content_title}>{i18n.t("account.my_orders.title")}</Text>
                <DataTable style={styles.table}>
                    <DataTable.Header style={{borderBottomWidth: 0}}>
                        <DataTable.Title
                            style={[styles.cell_with_right_border, styles.cell]}>{i18n.t("account.my_orders.table.number")}</DataTable.Title>
                        <DataTable.Title
                            style={[styles.cell_with_right_border, styles.cell]}>{i18n.t("account.my_orders.table.date")}</DataTable.Title>
                        <DataTable.Title
                            style={[styles.cell_with_right_border, styles.cell]}>{i18n.t("account.my_orders.table.total")}</DataTable.Title>
                        <DataTable.Title style={styles.cell}> </DataTable.Title>
                    </DataTable.Header>
                    {data.map((order: IOrderModel, index: number) => (
                        <DataTable.Row style={(index % 2 ? styles.table_row : styles.table_row_even)} key={index}>
                            <DataTable.Cell
                                style={[styles.cell_with_right_border, styles.cell]}>{order.number}</DataTable.Cell>
                            <DataTable.Cell
                                style={[styles.cell_with_right_border, styles.cell]}>{order.getFormattedCreateDate("DD/MM/YYYY")}</DataTable.Cell>
                            <DataTable.Cell
                                style={[styles.cell_with_right_border, styles.cell]}>{order.getPrice(state.clientStore.client.alwaysShowDecimals)}</DataTable.Cell>
                            <DataTable.Cell style={styles.cell}>
                                <TouchableOpacity activeOpacity={props.theme.defaultTouchableOpacity}>
                                    <Text
                                        style={{color: props.theme.colors.button_color}}>{i18n.t("account.my_orders.table.details")}</Text>
                                </TouchableOpacity>
                            </DataTable.Cell>
                        </DataTable.Row>
                    ))}
                    <DataTable.Pagination
                        style={{borderTopWidth: 1}}
                        page={page}
                        numberOfPages={Math.ceil(data.length / itemsPerPage)}
                        onPageChange={(page: number) => {
                            console.log(page);
                            setPage(page);
                        }}
                        label={`${from + 1}-${to} of ${data.length}`}
                    />
                </DataTable>
            </View>
        );
    }
);

export default withTheme(MyOrders);