import React from 'react';
import {StyleSheet, View} from 'react-native';
import Login from '../components/Login';
import { NavigationContainer } from '@react-navigation/native';

export default function LoginPage() {

    return (
        <View style={styles.container}>
            <Login/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        alignItems: "center",
        backgroundColor: '#eee',
        justifyContent: 'center',
    },
});
