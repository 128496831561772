import {NetworkService} from "../services/NetworkService";
import {Instance, types} from "mobx-state-tree";
import {makeAutoObservable} from "mobx";
import {runInAction} from "mobx"
import i18n from '../translations/Translations';
import {OptionModel} from "./OptionModel";
import moment from "moment"

export class ClientStore {
    client;

    constructor() {

        this.client = ClientModel.create();
        makeAutoObservable(this);
        this.loadClient();
    }

    loadClient() {
        NetworkService.getRequest("api/clients/current").then(clientJson => {
            runInAction(() => {
                this.client = ClientModel.create(clientJson);
            })
        }).catch(error => {
            console.log("Could not get client");
            console.log(error);
        });
    }
}

const CollectTimeModel = types.model({
    label: "",
    value: ""
});

export interface ICollectTimeModel extends Instance<typeof CollectTimeModel>{}

const ClientModel = types.model({
    id: "",
    name: "",
    address: "",
    phoneNumber: "",
    email: "",
    allowOnlinePayment: false,
    alwaysShowDecimals: true,
    clientType: "",
    headerImage: "",
    openingHours: types.array(types.array(types.array(types.string))),
    preparationTime: 0,
    logo: ""
}).actions((self) => ({

})).views(self => ({
    getHeaderImage(): string {
        return NetworkService.baseURL + self.headerImage;
    },
    getLogoImage(): string{
        return NetworkService.baseURL + self.logo;
    },
    getTodayOpeningHour(): string {
        let currentDay = (new Date().getDay() || 7) - 1;
        let currentDayData = self.openingHours[currentDay];
        let today = new Date();
        let currentTime = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0');
        let openText = 'Closed';
        for (let timePeriod of currentDayData) {
            let start = timePeriod[0];
            let end = timePeriod[1];
            if (currentTime > start && currentTime < end) {
                let startTimeWithoutSeconds = start.substr(0, 5);
                let entTimeWithoutSeconds = end.substr(0, 5);
                switch (entTimeWithoutSeconds.substr(3)) {
                    case '59':
                        entTimeWithoutSeconds = (parseInt(entTimeWithoutSeconds.substr(0, 2)) + 1) + ':00';
                        break;
                    case '44':
                        entTimeWithoutSeconds = entTimeWithoutSeconds.substr(0, 2) + ':45';
                        break;
                    case '29':
                        entTimeWithoutSeconds = entTimeWithoutSeconds.substr(0, 2) + ':30';
                        break;
                    case '14':
                        entTimeWithoutSeconds = entTimeWithoutSeconds.substr(0, 2) + ':15';
                        break;
                }
                openText = startTimeWithoutSeconds + ' - ' + entTimeWithoutSeconds;
                break;
            }
        }

        return openText;
    },
    getCloseTime(): string{
        let closeTime = "";
        let currentDay = (new Date().getDay() || 7) - 1;
        if (!self.openingHours || !self.openingHours[currentDay]) {
            return closeTime;
        }

        // if (!self.takingOrders) {
        //     return closeTime;
        // }

        let currentDayData = self.openingHours[currentDay];
        let today = new Date();
        let currentTime = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0');

        for (let i = 0; i < currentDayData.length; i++) {
            let timePeriod = currentDayData[i];
            let start = timePeriod[0];
            let end = timePeriod[1];
            if (currentTime > start && currentTime < end) {
                return end.substr(0, 5)
            }
        }

        return closeTime;
    },
    getCollectTimes():Array<ICollectTimeModel>{
        let currentTime = moment();
        currentTime.set({second: 0, millisecond: 0});
        let asapTime = currentTime.add(self.preparationTime, 'minutes');
        let result = [CollectTimeModel.create({label: i18n.t("order_modal.collect.asap"), value: asapTime.toISOString()})];

        let optionDate = asapTime;
        let restaurantCloseTime = this.getCloseTime();
        if (restaurantCloseTime.length === 0){
            return result;
        }

        let remainder = function (date: moment.Moment) {
            return 15 - (date.minute() % 15)
        };
        optionDate = moment(optionDate).add(remainder(optionDate), "minutes");
        // Generate options
        while (optionDate.format('HH:mm') < restaurantCloseTime && optionDate.format('DD') === currentTime.format('DD')) {
            result.push(CollectTimeModel.create({label: optionDate.format('HH:mm'), value: optionDate.toISOString()}))
            optionDate = optionDate.add(remainder(optionDate), "minutes");
        }

        return result;
    }
}));