export default {
    common: {
        cancel: "Cancel",
        add: "Add",
        edit: "edit",
        confirm: "Confirm",
        ok: "OK",
        pages: {
            home: "Home Page",
            cart: "My Cart",
            account: "My account",
            search: "Search result"
        },
        more: "more",
        log_out: "Log out"
    },
    homePage: {
        menu: "Menu"
    },
    header: {
        cart: "My cart",
        account: "My account",
        logout: "Logout",
        search_placeholder: "search..."
    },
    cart: {
        title: "Your order",
        total: "Total",
        order: "Order!",
    },
    options_modal: {
        title: "Choose options",
        min: "min.",
        max: "max."
    },
    addons_modal: {
        title: "Choose addons"
    },
    order_modal: {
        title: "Order",
        contact_form: {
            title: "Your contact details",
            firstname: "First name",
            surname: "Surname",
            email: "E-mail",
            phone: "Phone"
        },
        collect: {
            title: "Collect at:",
            asap: "As fast as possible"
        },
        payment: {
            title: "Payment:",
            online: "Online",
            on_collect: "On collect"
        },
        errors: {
            firstName: "Missing first name",
            lastName: "Missing name",
            email: "Email has incorrect format",
            phone: "Missing phone number"
        }
    },
    product: {
        buttons: {
            buy: "buy now!"
        }
    },
    product_list: {
        load_more_button: "MORE PRODUCTS"
    },
    products_proposition: {
        title: "you may also like:"
    },
    footer: {
        copyright: "Copyright"
    },
    order_status_popup: {
        preparing: "Hello Ms/Mr %s %s\nThe restaurant has accepted your order.\nYour order will be ready in:\n%s minutes.",
        ready_for_pickup: "Ms/Mr %s %s,\n\nThank you for your patience.\nYour No %s order is ready.\nYou can come and collect it",
        waiting_approval: "Please wait, the restaurant validates your order.",
        rejected: "We are sorry. The restaurant could not take your order. Please contact the restaurant directly."
    },
    sort_filter_model: {
        srtNameAsc: "Name A-Z",
        srtNameDesc: "Name Z-A",
        srtPriceAsc: "Lowest Price",
        srtPriceDesc: "Highest Price",
    },
    login_page: {
        title: "MY ACCOUNT",
        login: "login",
        password: "password",
        sign_in: "SIGN IN",
        create: "Create an account",
        forgot: "Forgot your password?",
        errors: {
            missing: "Please fill both fields",
            incorrect: "Incorrect credentials",
        }
    },
    order_page: {
        tittle: "MY CART",
        cart_summary_headers: {
            product: "Product",
            price: "Price",
            quantity: "Quantity",
            total: "Total"
        },
        cart_price_summary: {
            sub_total: "Sub total",
            shipping: "Shipping",
            postal_code: "Your postal code:",
            total: "Total",
            order_button: "ORDER!"
        }
    },
    my_address: {
        title: "my address",
        choose_button_text: "choose",
        name: "Name",
        surname: "Surname",
        email: "E-mail",
        phone: "Phone number",
        address: "Address",
        postal_code: "Postal code",
        city: "City"
    },
    account: {
        menu: {
            password: "CHANGE PASSWORD",
            orders: "MY ORDERS",
            addresses: "MY ADDRESSES"
        },
        change_password: {
            title: "CHANGE MY PASSWORD",
            description: "To change your password, type your old password, new password and confirm it by typing the new password again.",
            old: "old password",
            new: "new password",
            confirm: "confirm new password",
            change: "CHANGE!",
            errors: {
                empty: "Make fill all fields",
                incorrect: "Incorrect old password",
                mismatch: "Please make sure your passwords match",
            },
            success: "Password changed successfully"
        },
        my_orders: {
            title: "MY ORDERS",
            table: {
                number: "Order number",
                date: "Date",
                total: "Total amount",
                details: "details"
            }
        },
        my_addresses: {
            title: "MY ADDRESSES"
        },
        create_account:{
            title: "CREATE ACCOUNT",
            btn: "SEND",
            description: "Fill following fields",
            email: "email",
            name: "name",
            surname: "surname",
            password: "password",
            rePassword: "repeat password",
            error: {
                empty: "You need to fill all the fields",
                email: "Incorrect email format",
                password_match: "Password doesn't match",
                params: "Missing parameters",
                already_used: "Email already used"
            }
        }
    },
    forgot_password: {
        title: "FORGOT YOUR PASSWORD?",
        description: "Fill in your email to receive message with reset link",
        email: "your email",
        btn: "SEND",
        message: "Email sent, follow the instructions provided in it",
    },
    articles_list: {
        title: "articles"
    }
}