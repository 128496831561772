import {DarkTheme as PaperDarkTheme, DefaultTheme as PaperDefaultTheme,} from 'react-native-paper';

declare global {
    namespace ReactNativePaper {
        //   interface ThemeColors {
        //     myOwnColor: string;
        //   }

        interface Theme {
            logo: string;
        }
    }
}


const CombinedDefaultTheme = {
    ...PaperDefaultTheme,
    colors: {
        ...PaperDefaultTheme.colors,
        accent: '#020054',
        primary: '#2D8FCB',
    },
    logo: 'aji-logo.png',
};
const CombinedDarkTheme = {
    ...PaperDarkTheme,
    logo: 'aji-logo.png',
};

export default CombinedDefaultTheme;
export {CombinedDefaultTheme, CombinedDarkTheme};