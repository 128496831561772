export default {
    common: {
        cancel: "Annuler",
        add: "Ajouter",
        edit: "éditer",
        confirm: "Confirmer",
        ok: "OK",
        pages: {
            home: "Page d'accueil",
            cart: "Mon panier",
            account: "Mon compte",
            search: "Search result"
        },
        more: "suite",
        log_out: "Se déconnecter"
    },
    homePage: {
        menu: "Menu",
    },
    header: {
        cart: "Mon panier",
        account: "Mon compte",
        logout: "Se déconnecter",
        search_placeholder: "chercher..."
    },
    cart: {
        title: "Votre commande",
        total: "Total",
        order: "Commander!",
    },
    options_modal: {
        title: "Choisissez les options",
        min: "min.",
        max: "max.",
    },
    addons_modal: {
        title: "Choisissez Modules complémentaires",
    },
    order_modal: {
        title: "Commander",
        contact_form: {
            title: "Détails de votre contact",
            firstname: "Prénom",
            surname: "Nom de famille",
            email: "E-mail",
            phone: "Téléphone",
        },
        collect: {
            title: "Récupérez à:",
            asap: "Aussi vite que possible",
        },
        payment: {
            title: "Paiement:",
            online: "En ligne",
            on_collect: "Sur collecte",
        },
        errors: {
            firstName: "Prénom manquant",
            lastName: "Nom manquant",
            email: "Le format de l'e-mail est incorrect",
            phone: "Numéro de téléphone manquant"
        }
    },
    product: {
        buttons: {
            buy: "buy now!"
        }
    },
    product_list: {
        load_more_button: "PLUS DE PRODUITS"
    },
    products_proposition: {
        title: "you may also like:"
    },
    footer: {
        copyright: "Copyright"
    },
    order_status_popup: {
        preparing: "Bonjour Mme/M %s %s\nLe restaurant a accepté votre commande.\nVotre commande sera prête dans:\n%s minutes.",
        ready_for_pickup: "Mme/M %s %s,\n\nMerci pour votre patience.\nVotre commande No %s est prête.\nVous pouvez venir la récupére",
        waiting_approval: "Veuillez patienter, le restaurant valider votre commande.",
        rejected: "Nous sommes désolés. Le restaurant n`a pas pu prendre votre commande. Veuillez contacter directement le restaurant."
    },
    sort_filter_model: {
        srtNameAsc: "Name A-Z",
        srtNameDesc: "Name Z-A",
        srtPriceAsc: "Lowest Price",
        srtPriceDesc: "Highest Price",
    },
    login_page: {
        title: "MON COMPTE",
        login: "login",
        password: "le mot de passe",
        sign_in: "S'IDENTIFIER",
        create: "Créer un compte",
        forgot: "Mot de passe oublié?",
        errors: {
            missing: "Veuillez remplir les deux champs",
            incorrect: "Identifiants incorrects",
        }
    },
    order_page: {
        tittle: "MY CART",
        cart_summary_headers: {
            product: "Product",
            price: "Price",
            quantity: "Quantity",
            total: "Total"
        },
        cart_price_summary: {
            sub_total: "Sub total",
            shipping: "Shipping",
            postal_code: "Your postal code:",
            total: "Total",
            order_button: "ORDER!"
        }
    },
    account: {
        menu: {
            password: "CHANGE PASSWORD",
            orders: "MY ORDERS",
            addresses: "MY ADDRESSES"
        },
        change_password: {
            title: "CHANGER MON MOT DE PASSE",
            description: "Pour modifier votre mot de passe, saisissez votre ancien mot de passe, votre nouveau mot de passe et confirmez-le en saisissant à nouveau le nouveau mot de passe.",
            old: "ancien mot de passe",
            new: "nouveau mot de passe",
            confirm: "confirmer le nouveau passe",
            change: "CHANGEMENT!",
            errors: {
                empty: "Faire remplir tous les champs",
                incorrect: "Ancien mot de passe incorrect",
                mismatch: "Merci de vérifier que vos mots de passe correspondent",
            },
            success: "Le mot de passe a été changé avec succès"
        },
        my_orders: {
            title: "MES COMMANDES",
            table: {
                number: "Numéro de commande",
                date: "Date",
                total: "Montant total",
                details: "des détails",
            }
        },
        my_addresses: {
            title: "MES ADRESSES"
        },
        create_account:{
            title: "CRÉER UN COMPTE",
            btn: "ENVOYER",
            description: "Remplissez les champs suivants",
            email: "e-mail",
            name: "nom",
            surname: "nom de famille",
            password: "le mot de passe",
            rePassword: "Répéter le mot de passe",
            error: {
                empty: "Vous devez remplir tous les champs",
                email: "Format d'e-mail incorrect",
                password_match: "Le mot de passe ne correspond pas",
                params: "Paramètres manquants",
                already_used: "Adresse e-Mail déjà utilisée"
            }
        }
    },
    forgot_password: {
        title: "MOT DE PASSE OUBLIÉ?",
        description: "Remplissez votre e-mail pour recevoir un message avec un lien de réinitialisation",
        email: "votre email",
        btn: "ENVOYER",
        message: "E-mail envoyé, suivez les instructions qui y sont fournies",
    },
    articles_list: {
        title: "articles"
    }
}