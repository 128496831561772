import React from 'react';
import {
    Image,
    StyleSheet, TextInput, TouchableOpacity,
    View
} from 'react-native';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import {withTheme} from "react-native-paper";
import BreadCrumbs from "./BreadCrumbs";
import {Text} from 'react-native-paper';
import {IProductModel} from "../models/ProductModel";
import i18n from "../translations/Translations";
import {IOrderItemModel} from "../models/OrderItemModel";
import Entypo from "react-native-vector-icons/Entypo";
import {OrderHandler} from "../models/OrderModel";
import CustomerAddress from "./CustomerAddress";
import {BreadCrumbModel} from "../models/BreadCrumbModel";

export interface OrderPageProps {
    theme: ReactNativePaper.Theme,
}

const OrderPage = observer((props: OrderPageProps) => {
    const state = useMst();
    const [deliveryCost, setDeliveryCost] = React.useState(0);

    const styles = StyleSheet.create({
        container: {
            flexDirection: "column",
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase",
            fontSize: 20,
            letterSpacing: 2.5,
        },
        cartSummary: {
            borderWidth: 1,
            borderColor: "black",
            minWidth: 750
        },
        cart_item_bold: {
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase",
            fontSize: 14,
            letterSpacing: 1.5,
            paddingVertical: 12
        },
        cart_row: {
            flexDirection: "row",
            paddingVertical: 18
        },
        cart_row_product: {
            borderTopWidth: 1,
            alignItems: "center"
        },
        column_header_text: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 12,
            letterSpacing: 2
        },
        cart_product_image: {
            width: 120,
            height: 90,
            marginRight: 20
        },
        quantity_wrapper: {
            flexDirection: "row",
            alignSelf: "flex-start",
        },
        quantity_btn: {
            alignSelf: "center",
            justifyContent: "center",
            padding: 3
        },
        quantity_text: {
            fontFamily: props.theme.fontList.primaryFontMedium,
            fontSize: 14,
            letterSpacing: 2,
            textTransform: "uppercase",
            backgroundColor: props.theme.colors.accent,
            paddingVertical: 8,
            paddingHorizontal: 14,
            alignSelf: "center",
            justifyContent: "center",
            marginHorizontal: 6
        },
        cart_column_0: {flexBasis: 80, flexShrink: 30, flexGrow: 300, alignItems: "center"},
        cart_column_1: {flexBasis: 550, flexShrink: 50, flexGrow: 5500},
        cart_column_2: {flexBasis: 200, flexShrink: 100, flexGrow: 1500, alignItems: "center"},
        cart_column_3: {flexBasis: 200, flexShrink: 100, flexGrow: 1500, alignItems: "center"},
        cart_column_4: {flexBasis: 200, flexShrink: 100, flexGrow: 1500, alignItems: "center"},
        summary_text: {
            alignSelf: "center",
            fontSize: 14,
            fontFamily: props.theme.fontList.primaryFontDefault,
        },
        input_style: {
            alignSelf: "center",
            minHeight: 36,
            maxHeight: 250,
            width: "100%",
            outlineColor: "#00000000",
            backgroundColor: "#eeeeee",
            paddingLeft: 16,
            paddingRight: 16,
            fontFamily: props.theme.fontList.primaryFontDefault,
            letterSpacing: 2,
            paddingVertical: 8,
            marginVertical: 16
        },
        my_address_text_style: {
            fontFamily: props.theme.fontList.primaryFontDefault,
        },
        choose_address_button_text: {
            fontFamily: props.theme.fontList.primaryFontDefault,
            fontSize: 14,
            alignSelf: "center",
            color: props.theme.colors.button_color
        }
    });

    let breadCrumbsArray = [
        BreadCrumbModel.create({
            routeName: "Home",
            label: i18n.t("common.pages.home")
        }),
        BreadCrumbModel.create({
            routeName: "Cart",
            label: i18n.t("common.pages.cart")
        })
    ];

    function generateCartSummaryItem(orderItem: IOrderItemModel) {
        let product = state.productsStore.products.find((p: IProductModel) => orderItem.product.id == p.id) as IProductModel;
        return <View style={[styles.cart_row, styles.cart_row_product]} key={orderItem.id}>
            <View style={styles.cart_column_0}>
                <TouchableOpacity onPress={() => {
                    const orderHandlerInstance = new OrderHandler(state);
                    orderHandlerInstance.removeOrderItem(orderItem);
                }} activeOpacity={0.8}>
                    <Entypo name="cross" color={props.theme.colors.button_color} size={32}/>
                </TouchableOpacity>
            </View>
            <View style={[styles.cart_column_1, {flexDirection: "row", alignItems: "center"}]}>
                <Image style={styles.cart_product_image} source={{uri: orderItem.product.getImage()}}/>
                <Text style={styles.cart_item_bold}>{orderItem.product.name.getTranslated()}</Text>
            </View>
            <View style={styles.cart_column_2}>
                <Text
                    style={[styles.column_header_text, styles.cart_item_bold]}>{product?.getPrice(state.clientStore.client.alwaysShowDecimals)}
                </Text>
            </View>
            <View style={styles.cart_column_3}>
                <View style={styles.quantity_wrapper}>
                    <TouchableOpacity style={styles.quantity_btn} onPress={() => {
                        const orderHandlerInstance = new OrderHandler(state);
                        orderHandlerInstance.decrementProductQuantity(orderItem);
                    }}>
                        <Entypo name="minus" color={props.theme.colors.button_color} size={24}/>
                    </TouchableOpacity>
                    <Text style={styles.quantity_text}>{orderItem.quantity}</Text>
                    <TouchableOpacity style={[styles.quantity_btn]}
                                      onPress={() => {
                                          const orderHandlerInstance = new OrderHandler(state);
                                          orderHandlerInstance.incrementProductQuantity(orderItem);
                                      }}>
                        <Entypo name="plus" color={props.theme.colors.button_color} size={24}/>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.cart_column_4}>
                <Text
                    style={[styles.column_header_text, styles.cart_item_bold]}>{orderItem.getPrice(state.clientStore.client.alwaysShowDecimals)}
                </Text>
            </View>
        </View>
    }

    function deliveryPostalCodeChange(text: string) {
        //TODO ADD PRICE CALCULATION, THIS CHANGE IS ONLY POSSIBLE IF USER ISN'T LOGGED IN!
    }

    let cartSummaryItems = [];
    for (let i = 0; i < state.currentOrder.items.length; i++) {
        cartSummaryItems.push(generateCartSummaryItem(state.currentOrder.items[i]));
    }

    let summaryPostalCodeInput = [];
    if (!state.customer.loggedIn) {
        summaryPostalCodeInput.push(<Text
            style={styles.summary_text}>{i18n.t("order_page.cart_price_summary.postal_code")}
        </Text>)
        summaryPostalCodeInput.push(
            <TextInput style={[styles.input_style, {width: 120}]} placeholder={"00-000"} maxLength={6}
                       onChangeText={deliveryPostalCodeChange}/>)
    }

    let customerAddressField = []
    if (state.customer.loggedIn) {
        customerAddressField.push(<CustomerAddress key={1}/>);
    }
    return (
        <View style={styles.container}>
            <BreadCrumbs breadCrumbs={breadCrumbsArray}/>
            <Text style={[styles.title, {paddingBottom: 24, paddingTop: 34}]}>{i18n.t("order_page.tittle")}</Text>
            <View style={styles.cartSummary}>
                <View style={styles.cart_row}>
                    <View style={styles.cart_column_0}/>
                    <View style={styles.cart_column_1}>
                        <Text style={styles.column_header_text}>{i18n.t("order_page.cart_summary_headers.product")}
                        </Text>
                    </View>
                    <View style={styles.cart_column_2}>
                        <Text style={styles.column_header_text}>{i18n.t("order_page.cart_summary_headers.price")}
                        </Text>
                    </View>
                    <View style={styles.cart_column_3}>
                        <Text style={styles.column_header_text}>{i18n.t("order_page.cart_summary_headers.quantity")}
                        </Text>
                    </View>
                    <View style={styles.cart_column_4}>
                        <Text style={styles.column_header_text}>{i18n.t("order_page.cart_summary_headers.total")}
                        </Text>
                    </View>
                </View>
                {cartSummaryItems}
            </View>
            <View style={{marginTop: 36, flexDirection: "row", justifyContent: "space-between"}}>
                {customerAddressField}
                <View style={{width: 370, alignSelf: "center", justifyContent:"flex-end"}}>
                    <View style={{
                        flexDirection: "row",
                        backgroundColor: props.theme.colors.accent,
                        justifyContent: "space-between",
                        paddingHorizontal: 22,
                        paddingVertical: 6
                    }}>
                        <Text style={[styles.summary_text, {fontFamily: props.theme.fontList.primaryFontBold}]}>
                            {i18n.t("order_page.cart_price_summary.sub_total")}</Text>
                        <Text style={[styles.summary_text, {fontSize: 16}]}>
                            {state.currentOrder.getPrice(state.clientStore.client.alwaysShowDecimals)}</Text>
                    </View>
                    <View style={{
                        flexDirection: "row", paddingHorizontal: 22,
                        paddingVertical: 6,
                        justifyContent: "space-between"
                    }}>
                        <View>
                            <Text
                                style={[styles.summary_text, {fontFamily: props.theme.fontList.primaryFontBold}]}>{i18n.t("order_page.cart_price_summary.shipping")}
                            </Text>
                        </View>
                        <View style={{alignContent: "center", justifyContent: "center", alignSelf: "center"}}>
                            {summaryPostalCodeInput}
                            <Text style={[styles.summary_text, {fontFamily: props.theme.fontList.primaryFontBold}]}>
                                DHL, 48h
                            </Text>
                        </View>
                        <View style={{alignSelf: "flex-end"}}>
                            <Text
                                style={[styles.summary_text, {fontSize: 16}]}>{state.currentOrder.getFormatedPrice(deliveryCost, state.clientStore.client.alwaysShowDecimals)}
                            </Text>
                        </View>
                    </View>
                    <View style={{
                        flexDirection: "row",
                        backgroundColor: props.theme.colors.accent,
                        justifyContent: "space-between",
                        paddingHorizontal: 22,
                        paddingVertical: 6
                    }}>
                        <Text
                            style={[styles.summary_text, {fontFamily: props.theme.fontList.primaryFontBold}]}>{i18n.t("order_page.cart_price_summary.total")}
                        </Text>
                        <Text style={[styles.summary_text, {
                            fontFamily: props.theme.fontList.primaryFontBold,
                            fontSize: 16
                        }]}>
                            {state.currentOrder.getFormatedPrice(state.currentOrder.price + deliveryCost, state.clientStore.client.alwaysShowDecimals)}
                        </Text>
                    </View>
                    <TouchableOpacity
                        style={{
                            backgroundColor: props.theme.colors.button_color,
                            alignContent: "center",
                            justifyContent: "center",
                            padding: 8,
                            marginTop: 28
                        }}>
                        <Text style={[styles.summary_text, {
                            alignSelf: "center",
                            color: "white",
                            fontFamily: props.theme.fontList.primaryFontBold,
                            letterSpacing: 2
                        }]}>{i18n.t("order_page.cart_price_summary.order_button")}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
})

export default withTheme(OrderPage);