import {useContext, createContext} from "react";

import {customerStore} from "./CustomerModel";
import {ClientStore} from "./ClientModel";
import {IProductModel, ProductModel, ProductsStore} from "./ProductModel";
import {ProductsCategoriesStore} from "./ProductCategoryModel";
import {optionPopupStore} from "./OptionPopupModel";
import {orderStore} from "./OrderModel";
import {addonPopupStore} from "./AddonPopupModel";
import {orderPopupStore} from "./OrderPopupModel";
import {errorPopupStore} from "./ErrorPopupModel";
import {ISortFilterModel, SortFilterModel} from "./SortFilterModel";
import {makeAutoObservable, makeObservable} from "mobx";
import {SearchStringModel} from "./SearchStringModel";

export class RootStore {
    clientStore;
    customer;
    productsStore;
    categoriesStore;
    optionsPopup;
    currentOrder;
    currentProduct;
    addonsPopup;
    orderPopup;
    errorPopup;
    selectedSortFilter: ISortFilterModel;
    readonly filterList: Array<ISortFilterModel> = [SortFilterModel.create({key: "srtNameAsc"}), SortFilterModel.create({key: "srtNameDesc"}),
        SortFilterModel.create({key: "srtPriceAsc"}), SortFilterModel.create({key: "srtPriceDesc"})];
    searchString;

    constructor() {
        this.clientStore = new ClientStore();
        this.productsStore = new ProductsStore();
        this.categoriesStore = new ProductsCategoriesStore();
        this.customer = customerStore;
        this.optionsPopup = optionPopupStore;
        this.addonsPopup = addonPopupStore;
        this.currentOrder = orderStore;
        this.currentProduct = ProductModel.create({});
        this.orderPopup = orderPopupStore;
        this.errorPopup = errorPopupStore;
        this.selectedSortFilter = SortFilterModel.create({key: this.filterList[0].key});
        this.searchString = SearchStringModel.create({});
    }

    setCurrentProduct(product: IProductModel) {
        this.currentProduct = product;
    }
}

// create context and provider to allow easy access to the central store from any component
const RootStoreContext = createContext<null | RootStore>(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}