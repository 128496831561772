import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import i18n from '../translations/Translations';
import {useMst} from "../models/RootModel";
import {observer} from "mobx-react-lite";
import ProductCategory from "./ProductCategory";
import {IProductCategoryModel} from "../models/ProductCategoryModel";
import Menu from "./Menu";
import Cart from "./Cart";
import {useMediaQuery} from "react-responsive";

export interface HomeProps {
    theme: ReactNativePaper.Theme,
}

const Home = observer((props: HomeProps) => {
    const state = useMst();
    const filteredCategories = state.categoriesStore.categories.filter((tmpCategory: IProductCategoryModel) => tmpCategory.getProducts(state.productsStore.products).length > 0);
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});

    const categoriesArray = filteredCategories.map((category: IProductCategoryModel) => (
        <ProductCategory key={category.id + "_wrapper"} category={category}/>
    ));

    let showCart = false;
    if (state.currentOrder.id.length > 0 && state.currentOrder.items.length > 0) {
        console.log(state.currentOrder.items[0].product)
        showCart = true;
    }


    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            alignSelf: "center",
            paddingHorizontal: 20
        },
        menu: {
            marginTop: 20,
            flexDirection: "column"
        },
        menu_title: {
            alignSelf: "center",
            fontFamily: props.theme.fontList.primaryFontBlack,
            fontSize: 36,
            textTransform: "uppercase",
            marginTop: 32,
            letterSpacing: 2
        },
        categories: {
            flexDirection: "column",
            marginLeft: 50,
            flexGrow: 1,
            flexShrink: 1,
        }
    });

    return (
        <View style={styles.container}>
            {desktopVersion &&
            <View style={styles.menu}>
                <Menu categories={filteredCategories}/>
                <View style={{marginTop: 50, width: 350, minHeight: 100}}>
                    {showCart &&
                    <Cart/>
                    }
                </View>
            </View>
            }
            <View style={styles.categories}>
                <Text style={styles.menu_title}>{i18n.t("homePage.menu")}</Text>
                {categoriesArray}
            </View>
        </View>
    );
});


export default withTheme(Home);