import {withTheme} from 'react-native-paper';
import * as React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import {OrderHandler} from "../models/OrderModel";
import CartSummary from "./CartSummary";

export interface CartProps {
    theme: ReactNativePaper.Theme,
    readonly?: boolean
}

const Cart = observer((props: CartProps) => {
    const state = useMst();

    function onOrderClick() {
        state.orderPopup.setIsPopupVisible(true);
    }

    function orderCancel() {
        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.cancel();
    }

    const styles = StyleSheet.create({
        container: {
            width: "100%",
            minHeight: 100,
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: 1,
            paddingTop: 20,
            minWidth: 400,
        },
        title: {
            color: props.theme.colors.primary,
            backgroundColor: "white",
            textTransform: "uppercase",
            fontFamily: props.theme.cart.cartTitleFont,
            fontSize:  props.theme.cart.cartTitleSize,
            alignSelf: props.theme.cart.cartTitleAlignSelf,
            letterSpacing: 2,
            paddingHorizontal: props.theme.cart.cartTitlePaddingHorizontal,
            marginHorizontal: props.theme.cart.cartTitleMarginHorizontal,
            position: "absolute",
            top: -15,
        },
        buttons: {
            marginTop: 10,
            marginBottom: 20,
        },
        order_button_wrapper: {
            paddingHorizontal: 30,
            paddingVertical: 10,
            backgroundColor: props.theme.colors.button_color,
            alignSelf: "center",
        },
        order_button: {
            fontSize: 18,
            alignSelf: "center",
            color: "white",
            fontFamily: props.theme.fontList.primaryFontBlack,
            textTransform: "uppercase",
            letterSpacing: props.theme.cart.cartButtonTextLetterSpacing
        },
        cancel_button_wrapper: {
            marginTop: 10,
            paddingHorizontal: 15,
            paddingVertical: 5,
            backgroundColor: "black",
            alignSelf: "center",
        },
        cancel_button: {
            fontSize: 9,
            alignSelf: "center",
            color: "white",
            fontFamily: props.theme.fontList.primaryFontBold,
            textTransform: "uppercase",
            letterSpacing: props.theme.cart.cartButtonTextLetterSpacing
        }
    });

    return (
        <View style={styles.container}>
            <Text style={[styles.title]}>{i18n.t("cart.title")}</Text>
            <CartSummary readonly={props.readonly}/>
            {!props.readonly &&
            <View style={styles.buttons}>
                <TouchableOpacity activeOpacity={0.7} style={styles.order_button_wrapper} onPress={onOrderClick}>
                    <Text style={styles.order_button}>{i18n.t("cart.order")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.cancel_button_wrapper} onPress={orderCancel}>
                    <Text style={styles.cancel_button}>{i18n.t("common.cancel")}</Text>
                </TouchableOpacity>
            </View>
            }
        </View>
    );
});

export default withTheme(Cart);