import {RadioButton, withTheme} from 'react-native-paper';
import * as React from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import i18n from '../translations/Translations';
import {observer} from "mobx-react-lite";
import {useMst} from "../models/RootModel";
import CartSummary from "./CartSummary";
import {Picker} from "@react-native-picker/picker";
import {ICollectTimeModel} from "../models/ClientModel";
import {useMediaQuery} from "react-responsive";
import { loadStripe } from "@stripe/stripe-js";
import {ItemValue} from "@react-native-picker/picker/typings/Picker";
import {NetworkService} from "../services/NetworkService";
import {OrderHandler} from "../models/OrderModel";

const stripePromise = loadStripe("pk_test_51HrWYCEVTkqZ6XJKzh54oh4B7r6fK4d3k6j2WCKdusLF2xVf3cvx94d9cPXXDST0CsywfHR1U8JVyen8GyVqHl5j00v2hA2Bvn");

export interface OrderPopupProps {
    theme: ReactNativePaper.Theme,
}

const OrderPopup = observer((props: OrderPopupProps) => {
    const state = useMst();
    const [deliveryTime, setDeliveryTime] = React.useState(state.clientStore.client.getCollectTimes()[0].value);
    const [paymentType, setPaymentType] = React.useState("online");
    const desktopVersion = useMediaQuery({minWidth: props.theme.desktopBreakpoint});

    function onClosePress() {
        state.orderPopup.setIsPopupVisible(false);
    }

    async function orderSubmit() {
        // Validate customer data and make order
        if (!validateFirstName()){
            state.errorPopup.setErrorMessage(i18n.t("order_modal.errors.firstName"))
            return;
        }
        if (!validateSurname()){
            state.errorPopup.setErrorMessage(i18n.t("order_modal.errors.lastName"))
            return;
        }
        if (!validateMail()){
            state.errorPopup.setErrorMessage(i18n.t("order_modal.errors.email"))
            return;
        }
        if (!validatePhone()){
            state.errorPopup.setErrorMessage(i18n.t("order_modal.errors.phone"))
            return;
        }

        const orderHandlerInstance = new OrderHandler(state);
        orderHandlerInstance.createCustomer(() => {
            orderHandlerInstance.setCustomer(async () => {
                if (paymentType === "online"){
                    const stripe = await stripePromise;
                    const response = await fetch(NetworkService.baseURL + "/api/create_checkout_session/" + state.currentOrder.id + '/collection/' + deliveryTime, {
                        method: "POST",
                    });
                    const session = await response.json();
                    // When the customer clicks on the button, redirect them to Checkout.
                    if (stripe != null) {
                        const result = await stripe.redirectToCheckout({
                            sessionId: session.id
                        });
                        if (result.error) {
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, display the localized error message to your customer
                            // using `result.error.message`.
                        }
                    }else{
                        // TODO This should never happen
                    }
                }else{
                    orderHandlerInstance.checkout(paymentType, deliveryTime, () => {
                        state.orderPopup.setIsPopupVisible(false);
                    })
                }
            });
        });
    }

    function validateFirstName() {
        return state.customer.firstName.length > 0;
    }

    function validateSurname() {
        return state.customer.lastName.length > 0;
    }

    function validateMail() {
        return state.customer.email.includes("@");
    }

    function validatePhone() {
        return state.customer.phoneNumber.length > 0;
    }

    const styles = StyleSheet.create({
        popup: {
            backgroundColor: "white",
            width: 850,
            height: "80vh",
            alignSelf: "center",
            paddingVertical: 53,
        },
        close_button: {
            position: "absolute",
            top: 20,
            right: 30
        },
        close_button_text: {
            fontFamily: props.theme.fontList.primaryFontBold,
            color: "#aaaaaa",
            fontSize: 36
        },
        title: {
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 24,
            alignSelf: "center",
            textTransform: "uppercase"
        },
        order_summary_wrapper: {
            flexDirection: "column",
            width: 364,
            paddingLeft: 34
        },
        order_summary_wrapper_mobile: {
            flexDirection: "column",
            width: "100%",
            paddingHorizontal: 34
        },
        section_title: {
            color: props.theme.colors.primary,
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 18,
        },
        order_summary_title: {
            marginTop: 40,
            marginBottom: 20
        },
        contact_form: {
            flexDirection: "column",
            width: 386,
            marginLeft: 100,
            paddingRight: 34
        },
        input_title: {
            marginTop: 20,
            marginBottom: 4
        },
        input_style: {
            height: 40,
            borderColor: "black",
            borderRadius: 3,
            borderWidth: 1,
            paddingLeft: 10
        },
        separator: {
            width: "100%",
            height: 1,
            backgroundColor: "black"
        },
        collect_wrapper: {
            flexDirection: "row",
            paddingVertical: 10,
        },
        collect_select: {
            width: 250,
            height: 40,
            paddingLeft: 10
        },
        collect_title: {
            marginVertical: "auto",
            marginRight: 50,
            marginLeft: "30%"
        },
        collect_title_mobile:{
            marginVertical: "auto",
            marginRight: 30,
            marginLeft: "10%"
        },
        payment_radio_wrapper: {
            flexDirection: "row"
        },
        payment_radio_label: {
            marginVertical: "auto",
            fontFamily: props.theme.fontList.primaryFontDefault
        },
        buttons_wrapper: {
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 50
        },
        buttons: {
            paddingTop: 6,
            paddingHorizontal: 24,
            paddingBottom: 6,
        },
        buttons_text: {
            textTransform: "uppercase",
            fontFamily: props.theme.fontList.primaryFontExtraBold,
            fontSize: 16
        },
        cancel_button: {
            backgroundColor: "#6c6c6c",
        },
        cancel_button_text: {
            color: "#959595"
        },
        add_button: {
            backgroundColor: props.theme.colors.primary,
            marginLeft: 24
        },
        add_button_text: {
            color: "#fff"
        }
    });

    return (
        <View style={[styles.popup, !desktopVersion && {width: "90%"}]}>
            <TouchableOpacity style={styles.close_button} onPress={onClosePress}>
                <Text style={styles.close_button_text}>×</Text>
            </TouchableOpacity>
            <Text style={styles.title}>{i18n.t("order_modal.title")}</Text>
            <ScrollView style={{height: "70%"}}>
                <View style={{flexDirection: desktopVersion ? "row" : "column"}}>
                    <View style={desktopVersion ? styles.order_summary_wrapper : styles.order_summary_wrapper_mobile}>
                        <Text style={[styles.section_title, styles.order_summary_title]}>{i18n.t("cart.title")}</Text>
                        <CartSummary/>
                    </View>
                    <View style={desktopVersion ? styles.contact_form : styles.order_summary_wrapper_mobile}>
                        <Text
                            style={[styles.section_title, styles.order_summary_title]}>{i18n.t("order_modal.contact_form.title")}</Text>
                        <Text style={styles.input_title}>{i18n.t("order_modal.contact_form.firstname")}</Text>
                        <TextInput style={styles.input_style} defaultValue={state.customer.firstName} onChangeText={text => {
                            state.customer.setFirstName(text)
                        }}/>
                        <Text style={styles.input_title}>{i18n.t("order_modal.contact_form.surname")}</Text>
                        <TextInput style={styles.input_style} defaultValue={state.customer.lastName} onChangeText={text => {
                            state.customer.setLastName(text)
                        }}/>
                        <Text style={styles.input_title}>{i18n.t("order_modal.contact_form.email")}</Text>
                        <TextInput style={styles.input_style} defaultValue={state.customer.email} onChangeText={text => {
                            state.customer.setEmail(text)
                        }}/>
                        <Text style={styles.input_title}>{i18n.t("order_modal.contact_form.phone")}</Text>
                        <TextInput style={styles.input_style} defaultValue={state.customer.phoneNumber} onChangeText={text => {
                            state.customer.setPhoneNumber(text)
                        }}/>
                    </View>
                </View>
                <View style={[styles.separator, {marginTop: 30}]}/>
                <View style={styles.collect_wrapper}>
                    <Text
                        style={[styles.section_title, desktopVersion ? styles.collect_title : styles.collect_title_mobile]}>{i18n.t("order_modal.collect.title")}</Text>
                    <Picker style={styles.collect_select} onValueChange={(value: ItemValue) => {setDeliveryTime(value.toString())}}>
                        {state.clientStore.client.getCollectTimes().map((collectTime: ICollectTimeModel) => (
                            <Picker.Item key={collectTime.value} label={collectTime.label} value={collectTime.value}/>
                        ))}
                    </Picker>
                </View>
                <View style={styles.separator}/>
                <View style={styles.collect_wrapper}>
                    <Text
                        style={[styles.section_title, desktopVersion ? styles.collect_title : styles.collect_title_mobile]}>{i18n.t("order_modal.payment.title")}</Text>
                    <View style={{flexDirection: "column"}}>
                        <TouchableOpacity activeOpacity={0.9} onPress={() => {
                            setPaymentType("online")
                        }} style={styles.payment_radio_wrapper}>
                            <RadioButton color={"#008ce3"} value={"online"}
                                         status={paymentType === "online" ? "checked" : "unchecked"} onPress={() => {
                                setPaymentType("online")
                            }}/>
                            <Text style={styles.payment_radio_label}>{i18n.t("order_modal.payment.online")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.9} onPress={() => {
                            setPaymentType("onCollect")
                        }} style={styles.payment_radio_wrapper}>
                            <RadioButton color={"#008ce3"} value={"online"}
                                         status={paymentType === "onCollect" ? "checked" : "unchecked"}
                                         onPress={() => {
                                             setPaymentType("onCollect")
                                         }}/>
                            <Text style={styles.payment_radio_label}>{i18n.t("order_modal.payment.on_collect")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
            <View style={styles.separator}/>
            <View style={styles.buttons_wrapper}>
                <TouchableOpacity style={[styles.buttons, styles.cancel_button]} onPress={onClosePress}
                                  activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.cancel_button_text]}>{i18n.t("common.cancel")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.buttons, styles.add_button]} onPress={orderSubmit}
                                  activeOpacity={0.75}>
                    <Text style={[styles.buttons_text, styles.add_button_text]}>{i18n.t("common.confirm")}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});


export default withTheme(OrderPopup);